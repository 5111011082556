import get from "lodash/get";
import { useMemo } from "react";
export var useMenuData = function(cosmicPageData) {
    return useMemo(function() {
        var links = (cosmicPageData.metadata.links || []).map(function(link) {
            return link.metadata;
        });
        var ribbon = cosmicPageData.metadata.ribbon;
        var userAccountLinks = cosmicPageData.metadata.user_accounts.navbar_user_account_links;
        userAccountLinks.dropdownOptions = get(cosmicPageData, "metadata.user_accounts.dropdown_options");
        userAccountLinks.helpLink = get(cosmicPageData, "metadata.help_link.metadata");
        return {
            ribbon: ribbon,
            links: links,
            userAccountLinks: userAccountLinks
        };
    }, [
        cosmicPageData
    ]);
};
